<template>
  <v-app>
    <v-system-bar />
    <SubAppBar title="เลือกแบบฟอร์มคัดกรอง" v-model="search" />
    <v-main class="primary">
      <v-container fluid>
        <v-card color="primary lighten-2">
          <v-card-title>แบบฟอร์มล่าสุด</v-card-title>
          <v-card-text>
            <v-row class="pt-2">
              <v-col
                v-for="(item, index) in filteredItem"
                :key="index"
                cols="12"
                class="pt-0"
              >
                <FormCard
                  :title="item.formTypeName"
                  :filename="item.img || require('@/assets/formavatar.svg')"
                  @onClick="selectMethod(item)"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="items.length>10" block text color="primary "
              ><span class="text-title-2">กดดูเพิ่มเติม</span></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-container>
      <v-dialog v-model="dialog">
        <SelectMethodCard :form="currentItem" @onClose="dialog = false" />
      </v-dialog>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>
import SubAppBar from "@/components/common/SubAppBar";
import FormCard from "@/components/formlist/FormCard";
import SelectMethodCard from "@/components/formlist/SelectMethodCard";
import { getForm, fromLogo } from "@/api/";
import { offlineimage } from "@/mixins/offlineimage";
export default {
  components: {
    SubAppBar,
    FormCard,
    SelectMethodCard,
  },
  mixins: [offlineimage],
  data() {
    return {
      dialog: false,
      loading: false,
      items: [],
      currentItem: null,
      search: "",
    };
  },
  computed: {
    networkStatus() {
      return this.isOnline ? "My network is fine" : "I am offline";
    },
    filteredItem() {
      return this.items.filter((obj) => obj.formTypeName.includes(this.search));
    },
  },
  methods: {
    selectMethod(item) {
      this.currentItem = item;
      this.dialog = true;
    },
    async fetchData() {
      this.error = this.post = null;
      this.role = this.$offlineStorage.get("role");
      const site = this.$offlineStorage.get("site");
      const tenant = this.$offlineStorage.get("tenant");
      if (navigator.onLine) {
        this.loading = true;
        try {
          let resp = await getForm({
            tenantId: tenant.tenantId,
            siteId: site.siteId,
          });
          if (resp.data.code === 1) {
            for (let i = 0; i < resp.data.result.length; i++) {
              if(resp.data.result[i].formLogo){
              let img = await fromLogo({
                tenantId: tenant.tenantId,
                siteId: site.siteId,
                formLogo: resp.data.result[i].formLogo,
              });
              resp.data.result[i].img = img.data.result;
              this.saveImage(img.data.result, resp.data.result[i].formLogo);
              }
            }
          }
          //console.log(resp.data.result)
          this.items = resp.data.result;
          this.$offlineStorage.set("formlist", resp.data.result);
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      } else {
        let list = this.$offlineStorage.get("formlist");
        if (list) {
          for (let i = 0; i < list.length; i++) {
            list[i].img = this.loadImage(list[i].formLogo);
          }
        } else {
          list = [];
        }
        this.items = list;
      }
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  border-radius: 8px;
}
</style>