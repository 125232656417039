<template>
     <v-app-bar app color="primary">
         <v-app-bar-nav-icon v-if="$route.name !== 'complete'" @click="$router.go(-1)">
       <template slot="default">
           <v-btn icon color="white" ><v-icon>mdi-arrow-left</v-icon></v-btn>
       </template>
         </v-app-bar-nav-icon>
        <v-spacer/>
   <v-app-bar-title class="text-title-1 natural--text">{{title}}</v-app-bar-title>
    <v-btn v-if="$route.name !== 'complete'" icon></v-btn>
   <v-spacer/>
     </v-app-bar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'หัวข้อแสดงผล H4' 
    },
  },

}
</script>

