<template>
    <v-card @click="$emit('onClick')"  >
                    <v-card-text class="pa-2">
                          <v-row >
                              <v-col cols="2">
                                  <v-avatar size="56" >
                                      <v-img :src="filename||require(`@/assets/NCDs.svg`)"></v-img>
                                  </v-avatar>
                              </v-col>
                              <v-col  align-self="center">
                                  <div class="ml-2 primary--text text--darken-4 text-body-large">{{title}}</div>
                              </v-col>
                          </v-row>
                 </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default:'' 
        },
        filename: {
            type: String,
            default:''
        }
    },

}
</script>

<style lang="scss" scoped>
.v-sheet.v-card {
  border-radius: 6px;
}
</style>