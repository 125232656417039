<template>
<v-sheet outlined rounded>
   <v-card outlined height="72" elevation="0"  @click="$emit('onClick')">
                        <v-card-text class="pa-2">
                          <v-row >
                              <v-col cols="3">
                                  <v-avatar size="56" >
                                      <v-img :src="require(`@/assets/${filename}`)"></v-img>
                                  </v-avatar>
                              </v-col>
                              <v-col cols="9" align-self="center">
                                  <div class="primary--text text--darken-4 text-body-large">{{title}}</div>
                              </v-col>
                          </v-row>
                 </v-card-text>
                 </v-card>
</v-sheet>
</template>

<script>
export default {
props: {
        title: {
            type: String,
            default:'' 
        },
        filename: {
            type: String,
            default:''
        }
    },
}
</script>

<style lang="scss" scoped>
 .v-card {
    border-radius: 6px;
    }
.theme--light.v-sheet--outlined {
    border-color: #BECEF3 !important;
    border-radius: 6px !important;
}
</style>