import { render, staticRenderFns } from "./FormCard.vue?vue&type=template&id=fe799c00&scoped=true&"
import script from "./FormCard.vue?vue&type=script&lang=js&"
export * from "./FormCard.vue?vue&type=script&lang=js&"
import style0 from "./FormCard.vue?vue&type=style&index=0&id=fe799c00&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe799c00",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VCard,VCardText,VCol,VImg,VRow})
